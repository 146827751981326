.slider1 .slick-list{
    margin-right: -15%;
}
.slider1 .slick-prev {
  top: -10%; 
  left: -4vw;
}
.slider1 .slick-prev:before{
  content: url('../../../assets//media//images//arrow-slider.svg') !important;
}
.slider1 .slick-next:before{
  content: url('../../../assets//media//images//arrow-slider-2.svg') !important;
}
.slider1 .slick-next {
    top: -10%; 
    left: -1vw;
  }
.slider1 img {
  width: 100%;
  height: 100%;
  }
.slide-spacing{
  padding-right: 20px !important;
}
.slider1 .slic-slide{
  width: min-content !important;
}
/* Mobile respnsiveness */
@media screen and (max-width: 768px) {
  .slide-spacing{
    padding-right: 10px !important;
  }
  .slider1 .slick-list{
    margin-right: 0%;
}
}