.subscribers{
    background-image: url('../../../../assets/media/images/map2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 600px;
    width: 100%;
}

/* Mobile responsiveness */
@media only screen and (max-width: 600px) {
    .subscribers{
        height: 400px;
    }
    
  }