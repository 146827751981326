/* Add this CSS to your stylesheets or in your component's style */
.slide-container {
    position: relative;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 30%;
    z-index: 300;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(27, 27, 41, 0.90) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 1;
  }
  .gradient-overlay:hover{
    opacity: 0;
  }
  .sliderfs img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  .content-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box; 
    z-index: 200;
  }
  .overlay{
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0;
    height: 75%; /* Adjust as needed */
    background: linear-gradient(to top,  rgb(251 128 69 / 0.9), rgb(255 61 84 / 0));
    transition: all 200ms;
    opacity: 0;
    transform-origin: bottom;
  }

  .overlay:hover{
    opacity: 1;
    transform: scaleY(1);
  }