.slider2 .slick-prev {
    left: 58% !important;
    top: 88.5%;
    z-index: 10;
    }
.slider2 .slick-next {
    right: 14% !important;
    top: 88.5%;
    z-index: 10;
  }
.slider2 .slick-dots{
    bottom: 7% !important;
    left: 23% !important;
    }
.slider2 .slick-prev:before{
    content: url('../../../assets//media//images//arrow-slider.svg') !important;
    }
    .slider2 .slick-next:before{
    content: url('../../../assets//media//images//arrow-slider-2.svg') !important;
    }
.slider2 .slick-dots li button:before{
    border-radius: 50% !important;
    opacity: 100% !important;
    border: 2px solid white !important;
    color: transparent !important;
    height: 8px !important;
    width: 8px !important;
}
.slider2 .slick-dots li.slick-active button:before{
    box-shadow:
    0 0 0 2px #1b1b29,  0 0 0 4px rgb(228, 54, 54);
}
.slider2>.slick-slider{
    position: relative;
    width: -webkit-fill-available !important;
}
@media screen and (max-width: 1024px) and (min-width: 786px)  {
    .slider2 .slick-prev {
        left: 63% !important;
        top: 83%;
        }
    .slider2 .slick-next {
        right: 0 !important;
        top: 83%;
    }
    .slider2 .slick-dots {
        bottom: 11% !important;
        left: 58% !important;
        width: 50%;
    }
}
@media screen and (max-width: 768px)  {
    .slider2 .slick-prev {
        left: 63% !important;
        top: 83%;
        }
    .slider2 .slick-next {
        right: 0 !important;
        top: 83%;
    }
    .slider2 .slick-dots {
        bottom: 11% !important;
        left: 55% !important;
        width: 50%;
    }
}
