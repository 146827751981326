.social-text{
    text-orientation: sideways-right;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-transform: uppercase;
}
.rotate-icon{
    transform: rotate(-270deg);
}
@media screen and (max-width: 1024px) {
    .rotate-icon{
        transform: rotate(180deg);
    }
}