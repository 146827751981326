.map{
    background-image: url('../../../assets/media/images/map.svg');
    height: 200px;
    width: 350px;
}
@media screen and (max-width:1024px) {
    .map{
        width: auto;
    }
}
